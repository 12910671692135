import { default as affiliatesIUOKO16a36Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsUyxDVIm2IcMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosqfcCQMbYQMMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93AT46LwzazXMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexcOeJPPyjY9Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93vxdnC4IKvwMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as index5ZYT97XEJ7Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93QMr1ihpHpmMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_938EfeCoWwxkMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as edittsldEkzROVMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index9NAeTtlnyTMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsTk8ovwmk0SMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexpHyK7XJQOrMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexJcTMSmfMk3Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharevjfbZRAr8dMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showTolW1leJJmMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexuQ1PGitNWOMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadslHEM1WrkFqMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guideszTwk7vphyOMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homezmwdk7TJ1FMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/home.vue?macro=true";
import { default as indextj7xxvUCG9Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationscXBHdsuQ9xMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as login7JCXzGojL1Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesz0hdvu8K2sMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencerstncNyhNe79Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_937C0meioAT3Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackzi3opLEGgqMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackomtXoEVXNXMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackQPf09xvyhnMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersqtn2pVPFjVMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93Tfz9greZnIMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as index0n92kiHKWjMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingIpDShlMBizMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policydCV4WqF1EEMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registermdxLMx5VebMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokensxZ27Ax79hfMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountQevSfCCQBbMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as admineWYvnOLdufMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingVPkgUcFoSKMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsX0iZzWa5OPMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainjmXRmIYvcmMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settings0g3Oogp3bsMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexyy8ele9jhPMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordJ2zIqBH7dXMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileDvdk6UICmuMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesafTOnBXCBYMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingswPkT6R4bv1Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/settings.vue?macro=true";
import { default as erroraaz7fPF2b4Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successx6NXBeLXC9Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsQmkFriYnuCMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as edit1DNhiBtD3wMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexSuAIVPzMSBMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as index2K8PQdYttIMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as shareBBc4txI2FCMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showq0vajV9OhmMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexUEa9KQgfy7Meta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexCltDpfTEKQMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsA3ajrQg8VRMeta } from "/codebuild/output/src759969111/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatesIUOKO16a36Meta?.name ?? "affiliates",
    path: affiliatesIUOKO16a36Meta?.path ?? "/affiliates",
    meta: affiliatesIUOKO16a36Meta || {},
    alias: affiliatesIUOKO16a36Meta?.alias || [],
    redirect: affiliatesIUOKO16a36Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsUyxDVIm2IcMeta?.name ?? "database-views",
    path: database_45viewsUyxDVIm2IcMeta?.path ?? "/database-views",
    meta: database_45viewsUyxDVIm2IcMeta || {},
    alias: database_45viewsUyxDVIm2IcMeta?.alias || [],
    redirect: database_45viewsUyxDVIm2IcMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngosqfcCQMbYQMMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngosqfcCQMbYQMMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosqfcCQMbYQMMeta || {},
    alias: discount_45students_45academics_45ngosqfcCQMbYQMMeta?.alias || [],
    redirect: discount_45students_45academics_45ngosqfcCQMbYQMMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AT46LwzazXMeta?.name ?? "features-slug",
    path: _91slug_93AT46LwzazXMeta?.path ?? "/features/:slug()",
    meta: _91slug_93AT46LwzazXMeta || {},
    alias: _91slug_93AT46LwzazXMeta?.alias || [],
    redirect: _91slug_93AT46LwzazXMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexcOeJPPyjY9Meta?.name ?? "features",
    path: indexcOeJPPyjY9Meta?.path ?? "/features",
    meta: indexcOeJPPyjY9Meta || {},
    alias: indexcOeJPPyjY9Meta?.alias || [],
    redirect: indexcOeJPPyjY9Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vxdnC4IKvwMeta?.name ?? "form-templates-slug",
    path: _91slug_93vxdnC4IKvwMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93vxdnC4IKvwMeta || {},
    alias: _91slug_93vxdnC4IKvwMeta?.alias || [],
    redirect: _91slug_93vxdnC4IKvwMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: index5ZYT97XEJ7Meta?.name ?? "form-templates",
    path: index5ZYT97XEJ7Meta?.path ?? "/form-templates",
    meta: index5ZYT97XEJ7Meta || {},
    alias: index5ZYT97XEJ7Meta?.alias || [],
    redirect: index5ZYT97XEJ7Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QMr1ihpHpmMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93QMr1ihpHpmMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93QMr1ihpHpmMeta || {},
    alias: _91slug_93QMr1ihpHpmMeta?.alias || [],
    redirect: _91slug_93QMr1ihpHpmMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938EfeCoWwxkMeta?.name ?? "form-templates-types-slug",
    path: _91slug_938EfeCoWwxkMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_938EfeCoWwxkMeta || {},
    alias: _91slug_938EfeCoWwxkMeta?.alias || [],
    redirect: _91slug_938EfeCoWwxkMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: edittsldEkzROVMeta?.name ?? "forms-slug-edit",
    path: edittsldEkzROVMeta?.path ?? "/forms/:slug()/edit",
    meta: edittsldEkzROVMeta || {},
    alias: edittsldEkzROVMeta?.alias || [],
    redirect: edittsldEkzROVMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index9NAeTtlnyTMeta?.name ?? "forms-slug",
    path: index9NAeTtlnyTMeta?.path ?? "/forms/:slug()",
    meta: index9NAeTtlnyTMeta || {},
    alias: index9NAeTtlnyTMeta?.alias || [],
    redirect: index9NAeTtlnyTMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showTolW1leJJmMeta?.name ?? undefined,
    path: showTolW1leJJmMeta?.path ?? "/forms/:slug()/show",
    meta: showTolW1leJJmMeta || {},
    alias: showTolW1leJJmMeta?.alias || [],
    redirect: showTolW1leJJmMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsTk8ovwmk0SMeta?.name ?? "forms-slug-show-analytics",
    path: analyticsTk8ovwmk0SMeta?.path ?? "analytics",
    meta: analyticsTk8ovwmk0SMeta || {},
    alias: analyticsTk8ovwmk0SMeta?.alias || [],
    redirect: analyticsTk8ovwmk0SMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexpHyK7XJQOrMeta?.name ?? "forms-slug-show",
    path: indexpHyK7XJQOrMeta?.path ?? "",
    meta: indexpHyK7XJQOrMeta || {},
    alias: indexpHyK7XJQOrMeta?.alias || [],
    redirect: indexpHyK7XJQOrMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexJcTMSmfMk3Meta?.name ?? "forms-slug-show-integrations",
    path: indexJcTMSmfMk3Meta?.path ?? "integrations",
    meta: indexJcTMSmfMk3Meta || {},
    alias: indexJcTMSmfMk3Meta?.alias || [],
    redirect: indexJcTMSmfMk3Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: sharevjfbZRAr8dMeta?.name ?? "forms-slug-show-share",
    path: sharevjfbZRAr8dMeta?.path ?? "share",
    meta: sharevjfbZRAr8dMeta || {},
    alias: sharevjfbZRAr8dMeta?.alias || [],
    redirect: sharevjfbZRAr8dMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexuQ1PGitNWOMeta?.name ?? "forms-create",
    path: indexuQ1PGitNWOMeta?.path ?? "/forms/create",
    meta: indexuQ1PGitNWOMeta || {},
    alias: indexuQ1PGitNWOMeta?.alias || [],
    redirect: indexuQ1PGitNWOMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadslHEM1WrkFqMeta?.name ?? "forms-uploads",
    path: uploadslHEM1WrkFqMeta?.path ?? "/forms/uploads",
    meta: uploadslHEM1WrkFqMeta || {},
    alias: uploadslHEM1WrkFqMeta?.alias || [],
    redirect: uploadslHEM1WrkFqMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guideszTwk7vphyOMeta?.name ?? "guides",
    path: guideszTwk7vphyOMeta?.path ?? "/guides",
    meta: guideszTwk7vphyOMeta || {},
    alias: guideszTwk7vphyOMeta?.alias || [],
    redirect: guideszTwk7vphyOMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homezmwdk7TJ1FMeta?.name ?? "home",
    path: homezmwdk7TJ1FMeta?.path ?? "/home",
    meta: homezmwdk7TJ1FMeta || {},
    alias: homezmwdk7TJ1FMeta?.alias || [],
    redirect: homezmwdk7TJ1FMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indextj7xxvUCG9Meta?.name ?? "index",
    path: indextj7xxvUCG9Meta?.path ?? "/",
    meta: indextj7xxvUCG9Meta || {},
    alias: indextj7xxvUCG9Meta?.alias || [],
    redirect: indextj7xxvUCG9Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationscXBHdsuQ9xMeta?.name ?? "integrations",
    path: integrationscXBHdsuQ9xMeta?.path ?? "/integrations",
    meta: integrationscXBHdsuQ9xMeta || {},
    alias: integrationscXBHdsuQ9xMeta?.alias || [],
    redirect: integrationscXBHdsuQ9xMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: login7JCXzGojL1Meta?.name ?? "login",
    path: login7JCXzGojL1Meta?.path ?? "/login",
    meta: login7JCXzGojL1Meta || {},
    alias: login7JCXzGojL1Meta?.alias || [],
    redirect: login7JCXzGojL1Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesz0hdvu8K2sMeta?.name ?? "my-templates",
    path: my_45templatesz0hdvu8K2sMeta?.path ?? "/my-templates",
    meta: my_45templatesz0hdvu8K2sMeta || {},
    alias: my_45templatesz0hdvu8K2sMeta?.alias || [],
    redirect: my_45templatesz0hdvu8K2sMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencerstncNyhNe79Meta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencerstncNyhNe79Meta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencerstncNyhNe79Meta || {},
    alias: notion_45ambassadors_45influencerstncNyhNe79Meta?.alias || [],
    redirect: notion_45ambassadors_45influencerstncNyhNe79Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937C0meioAT3Meta?.name ?? "notion-form-slug",
    path: _91slug_937C0meioAT3Meta?.path ?? "/notion-form/:slug()",
    meta: _91slug_937C0meioAT3Meta || {},
    alias: _91slug_937C0meioAT3Meta?.alias || [],
    redirect: _91slug_937C0meioAT3Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackzi3opLEGgqMeta?.name ?? "notion-callback",
    path: callbackzi3opLEGgqMeta?.path ?? "/notion/callback",
    meta: callbackzi3opLEGgqMeta || {},
    alias: callbackzi3opLEGgqMeta?.alias || [],
    redirect: callbackzi3opLEGgqMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callbackomtXoEVXNXMeta?.name ?? "notion-guest-callback",
    path: guest_45callbackomtXoEVXNXMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callbackomtXoEVXNXMeta || {},
    alias: guest_45callbackomtXoEVXNXMeta?.alias || [],
    redirect: guest_45callbackomtXoEVXNXMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackQPf09xvyhnMeta?.name ?? "oauth-callback",
    path: callbackQPf09xvyhnMeta?.path ?? "/oauth/callback",
    meta: callbackQPf09xvyhnMeta || {},
    alias: callbackQPf09xvyhnMeta?.alias || [],
    redirect: callbackQPf09xvyhnMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnersqtn2pVPFjVMeta?.name ?? "partners",
    path: partnersqtn2pVPFjVMeta?.path ?? "/partners",
    meta: partnersqtn2pVPFjVMeta || {},
    alias: partnersqtn2pVPFjVMeta?.alias || [],
    redirect: partnersqtn2pVPFjVMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Tfz9greZnIMeta?.name ?? "password-reset-token",
    path: _91token_93Tfz9greZnIMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93Tfz9greZnIMeta || {},
    alias: _91token_93Tfz9greZnIMeta?.alias || [],
    redirect: _91token_93Tfz9greZnIMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: index0n92kiHKWjMeta?.name ?? "password-reset",
    path: index0n92kiHKWjMeta?.path ?? "/password/reset",
    meta: index0n92kiHKWjMeta || {},
    alias: index0n92kiHKWjMeta?.alias || [],
    redirect: index0n92kiHKWjMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingIpDShlMBizMeta?.name ?? "pricing",
    path: pricingIpDShlMBizMeta?.path ?? "/pricing",
    meta: pricingIpDShlMBizMeta || {},
    alias: pricingIpDShlMBizMeta?.alias || [],
    redirect: pricingIpDShlMBizMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policydCV4WqF1EEMeta?.name ?? "privacy-policy",
    path: privacy_45policydCV4WqF1EEMeta?.path ?? "/privacy-policy",
    meta: privacy_45policydCV4WqF1EEMeta || {},
    alias: privacy_45policydCV4WqF1EEMeta?.alias || [],
    redirect: privacy_45policydCV4WqF1EEMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registermdxLMx5VebMeta?.name ?? "register",
    path: registermdxLMx5VebMeta?.path ?? "/register",
    meta: registermdxLMx5VebMeta || {},
    alias: registermdxLMx5VebMeta?.alias || [],
    redirect: registermdxLMx5VebMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingswPkT6R4bv1Meta?.name ?? undefined,
    path: settingswPkT6R4bv1Meta?.path ?? "/settings",
    meta: settingswPkT6R4bv1Meta || {},
    alias: settingswPkT6R4bv1Meta?.alias || [],
    redirect: settingswPkT6R4bv1Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokensxZ27Ax79hfMeta?.name ?? "settings-access-tokens",
    path: access_45tokensxZ27Ax79hfMeta?.path ?? "access-tokens",
    meta: access_45tokensxZ27Ax79hfMeta || {},
    alias: access_45tokensxZ27Ax79hfMeta?.alias || [],
    redirect: access_45tokensxZ27Ax79hfMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountQevSfCCQBbMeta?.name ?? "settings-account",
    path: accountQevSfCCQBbMeta?.path ?? "account",
    meta: accountQevSfCCQBbMeta || {},
    alias: accountQevSfCCQBbMeta?.alias || [],
    redirect: accountQevSfCCQBbMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: admineWYvnOLdufMeta?.name ?? "settings-admin",
    path: admineWYvnOLdufMeta?.path ?? "admin",
    meta: admineWYvnOLdufMeta || {},
    alias: admineWYvnOLdufMeta?.alias || [],
    redirect: admineWYvnOLdufMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingVPkgUcFoSKMeta?.name ?? "settings-billing",
    path: billingVPkgUcFoSKMeta?.path ?? "billing",
    meta: billingVPkgUcFoSKMeta || {},
    alias: billingVPkgUcFoSKMeta?.alias || [],
    redirect: billingVPkgUcFoSKMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsX0iZzWa5OPMeta?.name ?? "settings-connections",
    path: connectionsX0iZzWa5OPMeta?.path ?? "connections",
    meta: connectionsX0iZzWa5OPMeta || {},
    alias: connectionsX0iZzWa5OPMeta?.alias || [],
    redirect: connectionsX0iZzWa5OPMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainjmXRmIYvcmMeta?.name ?? "settings-custom-domain",
    path: custom_45domainjmXRmIYvcmMeta?.path ?? "custom-domain",
    meta: custom_45domainjmXRmIYvcmMeta || {},
    alias: custom_45domainjmXRmIYvcmMeta?.alias || [],
    redirect: custom_45domainjmXRmIYvcmMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: email_45settings0g3Oogp3bsMeta?.name ?? "settings-email-settings",
    path: email_45settings0g3Oogp3bsMeta?.path ?? "email-settings",
    meta: email_45settings0g3Oogp3bsMeta || {},
    alias: email_45settings0g3Oogp3bsMeta?.alias || [],
    redirect: email_45settings0g3Oogp3bsMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: indexyy8ele9jhPMeta?.name ?? "settings",
    path: indexyy8ele9jhPMeta?.path ?? "",
    meta: indexyy8ele9jhPMeta || {},
    alias: indexyy8ele9jhPMeta?.alias || [],
    redirect: indexyy8ele9jhPMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordJ2zIqBH7dXMeta?.name ?? "settings-password",
    path: passwordJ2zIqBH7dXMeta?.path ?? "password",
    meta: passwordJ2zIqBH7dXMeta || {},
    alias: passwordJ2zIqBH7dXMeta?.alias || [],
    redirect: passwordJ2zIqBH7dXMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileDvdk6UICmuMeta?.name ?? "settings-profile",
    path: profileDvdk6UICmuMeta?.path ?? "profile",
    meta: profileDvdk6UICmuMeta || {},
    alias: profileDvdk6UICmuMeta?.alias || [],
    redirect: profileDvdk6UICmuMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacesafTOnBXCBYMeta?.name ?? "settings-workspaces",
    path: workspacesafTOnBXCBYMeta?.path ?? "workspaces",
    meta: workspacesafTOnBXCBYMeta || {},
    alias: workspacesafTOnBXCBYMeta?.alias || [],
    redirect: workspacesafTOnBXCBYMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: erroraaz7fPF2b4Meta?.name ?? "subscriptions-error",
    path: erroraaz7fPF2b4Meta?.path ?? "/subscriptions/error",
    meta: erroraaz7fPF2b4Meta || {},
    alias: erroraaz7fPF2b4Meta?.alias || [],
    redirect: erroraaz7fPF2b4Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successx6NXBeLXC9Meta?.name ?? "subscriptions-success",
    path: successx6NXBeLXC9Meta?.path ?? "/subscriptions/success",
    meta: successx6NXBeLXC9Meta || {},
    alias: successx6NXBeLXC9Meta?.alias || [],
    redirect: successx6NXBeLXC9Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsQmkFriYnuCMeta?.name ?? "terms-conditions",
    path: terms_45conditionsQmkFriYnuCMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsQmkFriYnuCMeta || {},
    alias: terms_45conditionsQmkFriYnuCMeta?.alias || [],
    redirect: terms_45conditionsQmkFriYnuCMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: edit1DNhiBtD3wMeta?.name ?? "views-slug-edit",
    path: edit1DNhiBtD3wMeta?.path ?? "/views/:slug()/edit",
    meta: edit1DNhiBtD3wMeta || {},
    alias: edit1DNhiBtD3wMeta?.alias || [],
    redirect: edit1DNhiBtD3wMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSuAIVPzMSBMeta?.name ?? "views-slug",
    path: indexSuAIVPzMSBMeta?.path ?? "/views/:slug()",
    meta: indexSuAIVPzMSBMeta || {},
    alias: indexSuAIVPzMSBMeta?.alias || [],
    redirect: indexSuAIVPzMSBMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showq0vajV9OhmMeta?.name ?? undefined,
    path: showq0vajV9OhmMeta?.path ?? "/views/:slug()/show",
    meta: showq0vajV9OhmMeta || {},
    alias: showq0vajV9OhmMeta?.alias || [],
    redirect: showq0vajV9OhmMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: index2K8PQdYttIMeta?.name ?? "views-slug-show",
    path: index2K8PQdYttIMeta?.path ?? "",
    meta: index2K8PQdYttIMeta || {},
    alias: index2K8PQdYttIMeta?.alias || [],
    redirect: index2K8PQdYttIMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareBBc4txI2FCMeta?.name ?? "views-slug-show-share",
    path: shareBBc4txI2FCMeta?.path ?? "share",
    meta: shareBBc4txI2FCMeta || {},
    alias: shareBBc4txI2FCMeta?.alias || [],
    redirect: shareBBc4txI2FCMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexUEa9KQgfy7Meta?.name ?? "views-create",
    path: indexUEa9KQgfy7Meta?.path ?? "/views/create",
    meta: indexUEa9KQgfy7Meta || {},
    alias: indexUEa9KQgfy7Meta?.alias || [],
    redirect: indexUEa9KQgfy7Meta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexCltDpfTEKQMeta?.name ?? "views",
    path: indexCltDpfTEKQMeta?.path ?? "/views",
    meta: indexCltDpfTEKQMeta || {},
    alias: indexCltDpfTEKQMeta?.alias || [],
    redirect: indexCltDpfTEKQMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsA3ajrQg8VRMeta?.name ?? "views-uploads",
    path: uploadsA3ajrQg8VRMeta?.path ?? "/views/uploads",
    meta: uploadsA3ajrQg8VRMeta || {},
    alias: uploadsA3ajrQg8VRMeta?.alias || [],
    redirect: uploadsA3ajrQg8VRMeta?.redirect,
    component: () => import("/codebuild/output/src759969111/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]